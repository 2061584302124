.-tablesTab {
  /* border: 1px solid #007f00; */
  width: 100%;
  height: 70%;
  background: #fafafa;
}

.tablesControls {
  width: 100%;
  height: 55px;
  background-color: hsl(0, 0%, 100%);
}

.tablesDiv {
  width: 100%;
  height: 710px;
  margin: 10 10 10 10;
}

.icon {
  width: rem(13px);
  height: auto;
  vertical-align: rem(-1px);
  margin-right: rem(8px);
}

.expandIcon {
  transition: transform 0.2s;
}

.expandIconRotated {
  transform: rotate(90deg);
}

.mantine-1m3tpc2 {
  height: 545px !important;
  max-height: 545px !important;
  overflow: overlay;
}
.exportBtnContainerTable {
  /* width: 107px; */
  height: 29px;
  margin: 6px 15px 5px 8px;
  background-color: #666;
}

/* .exportBtnContainerTable :hover {
  background-color: #d3d3d3;
} */
