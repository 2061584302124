.-HomeDiv {
  background-color: #fafafa;
  display: flex;
}

.filtersDiv {
  width: 27%;
  margin-top: 35px;
}

.h4font-weight-light {
  width: 373px;
  height: 35px;
  margin: 12px 0px 19px 24px;
  font-family: Roboto;
  font-size: 23px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: 0.2px;
  color: #111;
}

.pleadfont-weight-light {
  width: 242px;
  height: 64px;
  margin: 12px 0px 19px 24px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.2px;
  color: #111;
  display: block;
}
.pleadfont-weight-Note {
  height: 64px;
  margin: 12px 0px 19px 24px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.2px;
  color: #111;
  display: block;
}
.Rectangle-home {
  width: 90%;
  height: 1px;
  margin: 19px 20px 5px 24px;
  background-color: #007f00;
}

.labOutputYear {
  width: 85px;
  height: 21px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: #111;
  margin: 19px 20px 5px 24px;
}

.labEnergyOut {
  /* width: 172px;
  height: 21px; */
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: #111;
  margin: 19px 20px 5px 24px;
}

.labOutputYearBackground {
  width: 70px;
  height: 36px;
  margin: 5px 20px 5px 24px;
  /* padding: 6px 10px 6px 15px; */
  /* border: solid 1px #dedede; */
}

.labEnergyOutBackground {
  width: 269px;
  height: 36px;
  /* padding: 6px 10px 6px 15px; */
  margin: 5px 10px 5px 24px;
  /* border: solid 1px #dedede; */
}
.labdropdownsFirst {
  display: flex;
}

.labOutYearDrop {
  /* width: 70px; */
  height: 36px;
  object-fit: contain;
  opacity: 1;
  border-color: #dedede;
  margin: 5px 20px 5px 24px;
  z-index: 999;
}

.labEnergyOutDrop {
  /* width: 269px; */
  height: 36px;
  /* object-fit: contain; */
  /* opacity: 0.8; */
  margin: 5px 20px 5px 24px;
  border-color: #dedede;
  background-color: white;
}

.tabsHome {
  width: 77%;
  height: 70%;
  margin: 5px 0 0;
  padding: 1px 1px 20px;
  display: flex;
  margin: 10px 10px 5px 24px;
}

.mapTab {
  width: 62px;
  height: 40px;
  margin: 0 15px 743px 20px;
  padding: 10px 0 0;
  /* border: solid 1px #007f00; */
}
.chartTab {
  width: 62px;
  height: 40px;
  margin: 0 0 0px;
  /* border: solid 1px #dedede; */
}

.btn-text-sm-text-default-light-lh-1-5 {
  width: 26px;
  height: 20px;
  margin: 0 18px 9px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: rgba(17, 17, 17, 0.84);
}
.btn-text-sm-text-grey-light-lh-1-5 {
  width: 32px;
  height: 20px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: rgba(17, 17, 17, 0.64);
}

/* Tab Container */
.Tabs {
  width: 100%;
}

.-taboverride {
  display: flex;
  margin: 0%;
  width: 50%;
  left: 0%;
}

.labExploreBy {
  width: 77px;
  height: 21px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: #111;
  margin: 19px 20px 5px 24px;
}
.labExploreByBackground {
  width: 373px;
  height: 36px;
  margin: 5px 20px 5px 24px;
}
.labExplorebydrop {
  /* width: 373px;
  height: 36px; */
  margin: 5px 20px 5px 24px;
  background-color: white;
}

.labSector {
  width: 61px;
  height: 21px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: #111;
  margin: 19px 20px 5px 24px;
}

.labSectorBackground {
  width: 373px;
  height: 36px;
  margin: 5px 20px 5px 24px;
}

.labSectordrop {
  /* width: 373px;
  height: 24px; */
  margin: 5px 20px 5px 24px;
  background-color: white;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: 0.1px;
  color: rgba(17, 17, 17, 0.64);
  border-color: #dedede;
}
.labCompanyName {
  width: 61px;
  height: 21px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: #111;
  margin: 19px 20px 5px 24px;
}

.labCompanyNameBackground {
  width: 373px;
  height: 36px;
  margin: 5px 20px 5px 24px;
}

.labCompanyNamedrop {
  /* width: 373px;
  height: 24px; */
  margin: 5px 20px 5px 24px;
  background-color: white;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: 0.1px;
  color: rgba(17, 17, 17, 0.64);
  border-color: #dedede;
}

.labVectorName {
  height: 21px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: #111;
  margin: 19px 20px 5px 24px;
}

.labVectorNameBackground {
  width: 373px;
  height: 36px;
  margin: 5px 20px 5px 24px;
}

.labVectorNamedrop {
  /* width: 373px;
  height: 24px; */
  margin: 5px 20px 5px 24px;
  background-color: white;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: 0.1px;
  color: rgba(17, 17, 17, 0.64);
  border-color: #dedede;
}

.labAreaSel {
  width: 100px;
  height: 21px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: #111;
  margin: 19px 20px 5px 24px;
}

.labAreaSelBackground {
  width: 373px;
  height: 36px;
  margin: 5px 20px 5px 24px;
}

.labSelAreadrop {
  /* width: 373px;
  height: 24px; */
  margin: 5px 20px 5px 24px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: 0.1px;
  color: rgba(17, 17, 17, 0.64);
  border-color: #dedede;
  background-color: white;
}
.labSelAreadrop:hover {
  /* width: 373px;
  height: 24px; */
  margin: 5px 20px 5px 24px;
  background-color: white;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: 0.1px;
  color: rgba(17, 17, 17, 0.64);
  border-color: #dedede;
}
.buttoncontainer {
  display: flex;
}
.saveBtnContainer {
  width: 98px;
  height: 29px;
  margin: 19px 20px 5px 24px;
  background-color: #666;
}

.clearBtnContainer {
  width: 115px;
  height: 29px;
  right: 15%;
  margin: 19px 20px 5px 168px;
  /* padding: 5px 15px 5px 6px; */
  border: solid 1px #0e0909;
  background-color: rgba(255, 255, 255, 0);
  margin-left: auto;
}

.save-btn-text {
  width: 55px;
  height: 19px;
  margin: 0 0 0 6px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.2px;
  text-align: center;
  color: rgba(255, 255, 255, 0.92);
}
.clear-btn-text {
  width: 64px;
  height: 19px;
  margin: 0 0 0 6px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.2px;
  text-align: center;
  color: rgba(17, 17, 17, 0.5);
}
.exportBtnContainer {
  width: 107px;
  height: 29px;
  margin: 6px 15px 5px 8px;
  background-color: #666;
}

.export-btn-text {
  width: 64px;
  height: 19px;
  margin: 0 0 0 6px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.2px;
  text-align: center;
  color: rgba(255, 255, 255, 0.92);
  text-align: center;
}

.save-png {
  width: 16px;
  height: 16px;
  margin: 0px 6px 1.5px 5px;
  object-fit: contain;
  opacity: 0.54;
}
.export-png {
  width: 16px;
  height: 16px;
  margin: 0px 6px 1.5px 5px;
  object-fit: contain;
  opacity: 0.54;
}

.css-tj5bde-Svg {
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
  background-image: url(../../Assets/images/down-24@2x.png);
}

.dropdownArrow {
  background: url(../../Assets/images/down-24@2x.png) !important;
}

.css-1u9des2-indicatorSeparator {
  opacity: 0 !important;
}
.css-13cymwt-control {
  border-radius: 0px !important;
}

.nav-tabs-set {
  background-color: #fafafa;
}
/* .nav-tabs .nav-link.active {
  border-color: 1px solid #007f00 !important;
} */

.tab-content > .active {
  display: block;
  /* border: 1px solid #007f00; */
}

.navItemCustom {
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fff;
  /* border: solid 1px #dedede; */
}

.tecValue {
  width: 33%;
  height: 113px;
  margin: 20px 20px 5px 0px;
  padding: 22px 0px 21px 10px;
  border: solid 1px #007f00;
  background-color: #fff;
}
.tceValue {
  width: 33%;
  height: 113px;
  margin: 20px 20px 5px 0px;
  padding: 22px 0px 21px 10px;
  border: solid 1px #007f00;
  background-color: #fff;
}
.nopValue {
  width: 33%;
  height: 113px;
  margin: 20px 0px 5px 0px;
  padding: 22px 40px 21px 20px;
  border: solid 1px #007f00;
  background-color: #fff;
}

.PJ-text {
  width: 66px;
  height: 66px;
  /* margin: 2px 6px 2px 33.5px; */
  font-family: Roboto;
  font-size: 35px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.86px;
  text-align: center;
  color: #007f00;
}

.PJ {
  width: 30px;
  height: 30px;
  margin: 28px 0 12px 6px;
  font-family: Roboto;
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: rgba(17, 17, 17, 0.64);
}

.h4font-weight-light-css {
  width: 156px;
  height: 70px;
  margin: 0 0 0 0;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: 0.2px;
  color: #111;
}

.h4font-weight-light-css-tce {
  width: 156px;
  height: 70px;
  margin: 0 0px 0 0;
  font-family: Roboto;
  font-size: 20;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: 0.2px;
  color: #111;
}
.million-tons {
  width: 137px;
  height: 30px;
  margin: 28px 0 12px 6px;
  font-family: Roboto;
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: rgba(17, 17, 17, 0.64);
}
.PJ-text-tce {
  width: 66px;
  height: 66px;
  /* margin: 2px 6px 2px 33.5px; */
  font-family: Roboto;
  font-size: 35px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.86px;
  text-align: center;
  color: #007f00;
}

.h4font-weight-light-css-nop {
  width: 156px;
  height: 70px;
  margin: 0 20.5px 0 0;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: 0.2px;
  color: #111;
}

.PJ-text-nop {
  width: 66px;
  height: 66px;
  /* margin: 2px 6px 2px 20.5px; */
  font-family: Roboto;
  font-size: 35px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.86px;
  text-align: center;
  color: #007f00;
}

.k {
  width: 14px;
  height: 30px;
  margin: 28px 0 12px 6px;
  font-family: Roboto;
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: rgba(17, 17, 17, 0.64);
}
.companyGroup {
  font-size: 16px;
  font-weight: bold; 
}
.companyGroup:hover {
  cursor: pointer;
  color: #007f00;
} 