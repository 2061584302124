.background-Popup {
  width: 1240px;
  min-width: 1000px;
  height: 575px;
  top: 20%;
  margin: 1px 0 0 1px;
  border-radius: 3px;
  border: solid 1px #ffee5c;
  background-color: #fffff0;
}
.modal {
  width: 1240px;
}
.p-text-black-light {
  width: 1000px;
  height: 500px;
  margin: 2px 0 0 7px;
  padding: 0 3px 0 2px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.15px;
  color: #111;
}

.boldCSS {
  font-weight: bold !important;
  font-size: 16px !important;
}

.plead-text-black-light {
  width: 1000px;
  height: 232px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.2px;
  color: #111;
}

.p-text-black-light-lab {
  width: 1000px;
  margin: 2px 0 0 7px;
  padding: 0 3px 0 2px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.15px;
  color: #111;
}
