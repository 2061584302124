.-GuideDiv {
  height: 800px;
  background-color: #fafafa;
  display: flex;
}
#react-doc-viewer #header-bar {
  background-color: #ededed;
}

#pdf-download {
  display: none;
}
