.-mapTab {
  /* border: 1px solid #007f00; */
  width: 100%;
  height: 70%;
  background: #fafafa;
}

.mapControls {
  width: 100%;
  height: 55px;
  background-color: #fff;
}

.SearchDiv {
  width: 288px;
  height: 55px;
  /*border: solid 1px #dedede;
   margin: 5px 601px 0 5px; */
  padding: 6px 10px 6px 15px;
}
.maptoolsdiv {
  padding: 5px 5px 5px 5px;
  border: solid 1px #ccc;
  background-color: rgba(255, 255, 255, 0);
  margin-left: 10px;
}
.mapDiv {
  width: 100%;
  height: 650px;
}

.maptools-btn-text {
  width: 56px;
  height: 19px;
  margin: 0 0 0 6px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.2px;
  text-align: center;
  color: rgba(17, 17, 17, 0.84);
  margin: 5px 0 0;
  padding: 1px 1px 20px;
}

.toolsIconclass {
  height: 16px;
  width: 16px;
  padding: 0 5px 0 0;
}

.mapLayers {
  border: solid 1px #ccc;
  background-color: rgba(255, 255, 255, 0);
  padding: 5px 5px 5px 5px;
  margin-left: 10px;
}

.mapLayers-btn-text {
  width: 61px;
  height: 19px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.2px;
  text-align: center;
  color: rgba(17, 17, 17, 0.84);
  margin: 5px 0 0;
  padding: 1px 1px 20px;
}

.LayersIconclass {
  height: 16px;
  width: 16px;
  padding: 0 5px 0 0;
}

.nav-tabs {
  background-color: transparent !important;
  /* border: 1px solid #007f00 !important; */
}

.offcanvas.offcanvas-end {
  top: 212px !important;
}

@import "https://js.arcgis.com/4.27/esri/themes/light/main.css";

.map-spinner-container {
  position: relative;
  width: 100%;
  height: 650px;
}

.mapDiv,
.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.spinner-container {
  left: 47%;
  right: auto;
  top: 47%;
  z-index: 1;
  height: 50px;
}
.spinner-container-comp {
  position: relative;
  left: 47%;
  right: auto;
  top: 47%;
  z-index: 1;
  height: 50px;
}
.spinner-container-country {
  position: relative;
  left: 47%;
  right: auto;
  top: 47%;
  z-index: 1;
  height: 50px;
}
.spinner-container-sector {
  position: relative;
  left: 47%;
  right: auto;
  top: 47%;
  z-index: 1;
  height: 50px;
}
