.-chartTab {
  /* border: 1px solid #007f00; */
  width: 100%;
  height: 75%;
  background: #fafafa;
}

.ChartControls {
  width: 100%;
  background-color: #fff;
}

.ChartSearchDiv {
  width: 288px;
  height: 36px;
  /* border: solid 1px #dedede;
  margin: 5px 601px 0 5px;  */
  padding: 6px 10px 6px 15px;
}
.ChartDiv {
  width: 100%;
  height: 900px;
}
.chartside {
  display: flex;
}
.chartsidetitle {
  display: flex;
}
.Bounds {
  width: 50%;
  height: 400px;
  margin: 20px 20px 20px 20px;
  padding: 11px 0 31px;
  border-radius: 8px;
  box-shadow: 0 10px 20px 0 rgba(35, 35, 35, 0.08);
  background-color: #fff;
}
.Energy-mix {
  width: 81px;
  height: 23px;
  margin: 0 200px 10px 29px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.25px;
  color: #111;
}
.rule-F2F2F2 {
  width: 100%;
  height: 1px;
  margin: 8px 0 0;
  background-color: #f2f2f2;
}

img.Info-24 {
  width: 24px;
  height: 24px;
  margin: 1px 17px 8px 398px;
  object-fit: contain;
}
.custom-tooltip {
  padding: 5px;
  border-radius: 2px;
}
