.navheight {
  height: 64px;
}
.Background {
  width: 1600px;
  height: 30px;
  margin: 0 0 35px;
  padding: 11px 24px 13px 20px;
  background-color: #fff;
}

.Product-Identifier {
  width: 150px;
  height: 20px;
  margin: 16px 30px 4px 22px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #111;
  position: absolute;
  left: 75px;
}

.-Building-Blocks-Light-bp-Logo-Small-Color {
  width: 60px;
  height: 60px;
  margin: 0 22px 0 0px;
  padding: 0 0px 0 0;
  position: absolute;
}

.-Home {
  width: 35px;
  height: 19px;
  margin: 16px 30px 4px 22px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: rgba(17, 17, 17, 0.92);
  position: absolute;
  left: 290px;
}

.-Decarb {
  width: 147px;
  height: 19px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: rgba(17, 17, 17, 0.64);
  margin: 16px 30px 4px 22px;
  position: absolute;
  left: 340px;
}

.-Guide {
  width: 34px;
  height: 19px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: rgba(17, 17, 17, 0.64);
  position: absolute;
  margin: 16px 30px 4px 22px;
  left: 500px;
}

.-Login {
  width: 38px;
  height: 19px;
  opacity: 0.8;
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: 0.3px;
  text-align: center;
  color: #111;
  left: 1400px;
  position: absolute;
  margin: 16px 30px 4px 22px;
}

.-PremiumAccess {
  width: 100px;
  height: 36px;
  padding: 8px 5px 5px 5px;
  background-color: #343434;
  position: absolute;
  margin: 7px 30px 4px 22px;
  left: 1446px;
  text-align: center;
}

.-premiumaccess-btn-text {
  width: 97px;
  height: 19px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.92);
}
