.-DecarbDiv {
  background-color: #fafafa;
  display: flex;
}

.decarbFiltersDiv {
  width: 27%;
  margin-top: 35px;
}

.decarbFiltersToolDiv {
  width: 373px;
  height: 400px;
  margin: 12px 110px 19px 24px;
  background: #dedede;
}

.h4font-weight-light {
  width: 373px;
  height: 35px;
  margin: 12px 0px 19px 24px;
  font-family: Roboto;
  font-size: 23px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: 0.2px;
  color: #111;
}

.pleadfont-weight-light {
  width: 242px;
  height: 64px;
  margin: 12px 0px 19px 24px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.2px;
  color: #111;
  display: block;
}
.Rectangle-decarb {
  width: 90%;
  height: 1px;
  margin: 19px 20px 5px 24px;
  background-color: #007f00;
}

.tabsDecarb {
  width: 73%;
  margin: 5px 0 0;
  padding: 1px 1px 20px;
  margin: 10px 10px 5px 24px;
  margin-top: 35px;
}
.decarbTabs {
  display: flex;
}

.saveBtnDecarbContainer {
  width: 98px;
  height: 29px;
  margin: 6px 15px 5px 8px;
  background-color: #666;
}

.saveDecarb-btn-text {
  width: 55px;
  height: 19px;
  margin: 0 0 0 6px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.2px;
  text-align: center;
  color: rgba(255, 255, 255, 0.92);
}

.exportBtnDecarbContainer {
  width: 107px;
  height: 29px;
  margin: 6px 15px 5px 8px;
  background-color: #666;
  cursor: pointer;
}

.exportDecarb-btn-text {
  width: 64px;
  height: 19px;
  margin: 0 0 0 6px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.2px;
  text-align: center;
  color: rgba(255, 255, 255, 0.92);
  text-align: center;
}

.save-png {
  width: 16px;
  height: 16px;
  margin: 0px 6px 1.5px 5px;
  object-fit: contain;
  opacity: 0.54;
}
.export-png {
  width: 16px;
  height: 16px;
  margin: 0px 6px 1.5px 5px;
  object-fit: contain;
  opacity: 0.54;
}

.decarbContent {
  border: 1px solid #007f00;
  width: 100%;
  height: 650px;
  background: #fafafa;
  overflow: auto;
}

.labselareaOptional {
  width: 45px;
  height: 21px;
  margin: 0 0 0 8px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.2px;
  color: rgba(17, 17, 17, 0.64);
}
.decarb-Rectangle {
  height: 115px;
  margin: 0 0 0 0;
  padding: 0 0 0 20px;
  border: solid 1px #007f00;
  background-color: #fff;
  display: flex;
}
.h4font-weight-light-decarb {
  width: 97px;
  height: 70px;
  margin: 25px 3px 18px 0;
  font-family: Roboto;
  font-size: 23px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: 0.2px;
  color: #111;
}
.Bounds-decarb {
  height: 500px;
  margin: 20px 20px 20px 20px;
  padding: 11px 0 31px;
  border-radius: 8px;
  box-shadow: 0 10px 20px 0 rgba(35, 35, 35, 0.08);
  background-color: #fff;
}
.plant-cards {
  height: 23px;
  margin: 0 200px 10px 29px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: 0.25px;
  color: #111;
}
.rule-F2F2F2 {
  width: 100%;
  height: 1px;
  margin: 8px 0 0;
  background-color: #f2f2f2;
}

.plant-details {
  width: 100%;
  height: 100px;
  margin: 2px 20px 20px 20px;
  color: #111;
  display: flex;
}
.plant-details-pathways {
  width: 100%;
  height: 300px;
  margin: 20px 20px 20px 20px;
  color: #111;
}

.plantdetails-name {
  width: 60%;
  height: 100px;
  margin: 20px 20px 20px 8px;
  color: #111;
}
.plantdetails-sector {
  width: 30%;
  height: 100px;
  margin: 20px 20px 20px 8px;
  color: #111;
}

.Plant-name-decarb {
  width: 136px;
  height: 23px;
  margin: 4px 6px 5px 0;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.77;
  letter-spacing: 0.2px;
  color: #111;
}
.Rectangle-vertical {
  width: 1px;
  height: 30px;
  margin: -2px 0 0 8px;
  background-color: #666;
}

.Rectangle-vertical-green {
  width: 1px;
  height: 113px;
  margin: 0 14px 0 3px;
  background-color: #007f00;
}
.plant-name-value-decarb {
  height: 23px;
  margin: 0 0 3px 4px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.77;
  letter-spacing: 0.09px;
  color: rgba(17, 17, 17, 0.64);
}

.novalue-Css {
  width: 100%;
  height: 300px;
  margin: 20px 20px 20px 20px;
  color: #111;
  text-align: center;
  margin-top: 20%;
}
.mantine-heightClass {
  max-height: 320px;
  height: 320px;
  max-width: 97%;
  width: 97%;
  overflow: hidden;
}
.total-title {
  width: 8%;
  height: 100px;
  margin: 20px 20px 20px 8px;
  color: #111;
}

.total-name {
  width: 15%;
  height: 100px;
  margin: 20px 20px 20px 8px;
  color: #111;
}
.total-sector {
  height: 113px;
  width: 70%;
  color: #111;
  overflow: hidden;
}

.mantine-1m3tpc2 {
  max-height: 315px !important;
  height: 315px !important;
  max-width: 97%;
  width: 97%;
  overflow: overlay;
}

.companyGroup {
  font-size: 16px;
  font-weight: bold;
}
.companyGroup:hover {
  cursor: pointer;
  color: #007f00;
}

.mrt-table .thead {
  position: sticky !important;
}
